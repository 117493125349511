function isOpenCheckValidate() {
    return true
}


/**
 * Checks if a given string is a valid email address.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email is valid, false otherwise.
 */
function isEmail(email) {
    if (!email) return false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

function isEmpty(values) {
    if (values != "" && values != null) {
        return false
    } else {
        return true
    }
}

/**
 * Checks if a phone number is a valid local phone number.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} - Returns true if the phone number is a valid local phone number, otherwise returns false.
 */
function isLocalPhoneNumber(phoneNumber){
    const localPhoneNumberRegex = /^0\d{9}$/;
    return localPhoneNumberRegex.test(phoneNumber);
}

/**
 * Checks if a phone number is in international format.
 *
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} - Returns true if the phone number is in international format, otherwise returns false.
 */
function isInternationalPhoneNumber(phoneNumber){
    const internationalPhoneNumberRegex = /^\+[1-9]{1,3}\d{9}$/;
    return internationalPhoneNumberRegex.test(phoneNumber);
}

/**
 * Checks if a phone number is valid.
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} - Returns true if the phone number is valid, otherwise false.
 */
function isPhone(phone){
    if (!phone) return;
    return isLocalPhoneNumber(phone) || isInternationalPhoneNumber(phone);
}

function isNumber(values) {
    if (values != "" && !isNaN(values)) {
        return false
    } else {
        return true
    }
}

function isValidateDate(name, values) {
    if (values != "" && !isNaN(values)) {
        if (name == "Day") {
            return !(values > 0 && values <= 31)
        } else if (name == "Month") {
            return !(values > 0 && values <= 12)
        } else if (name == "Year") {
            return !(values >= 1900 && values <= 2030)
        }
    } else {
        return true
    }
}

function isFuture(day, month, year) {
    var now = new Date();
    var date = new Date(year+'-'+month+'-'+day)
    if(date > now){
        return true;
    } else {
        return false;
    }
}

function isInvalidDate(day, month, year) {
    let date = new Date(year+'-'+month+'-'+day)
    return !(date instanceof Date && !isNaN(date))
}

var Weights = new Array(1, 4, 3, 7, 5, 8, 6, 9, 10);

function CheckTFN(numberTFN) {
    if (numberTFN != undefined) {
        if (numberTFN.length == 8) {
            return true
        }
        if (numberTFN.length != 9) { 
            return true
        }
        var total = 0;
        for (var i = 0; i < 9; i++)
        total += Weights[i] * numberTFN.charAt(i);
        if (total == 0 || total % 11 != 0) {
            return true
        } else {
            return false
        }
    } 
    return true
}

function isSignName(string) {
    var re = /^[a-zA-z]+(\s[a-zA-z]+)*$/;
    return re.test(string)
}

export {
    isOpenCheckValidate,
    isEmpty,
    isEmail,
    isValidateDate,
    isFuture,
    isInvalidDate,
    isNumber,
    isPhone,
    CheckTFN,
    isSignName
}